var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M13.25 2.656A7.373 7.373 0 0 0 8 .5C3.875.5.5 3.875.5 8c0 1.313.375 2.625 1.031 3.75L.5 15.5l3.938-1.031c1.125.562 2.343.937 3.562.937 4.125 0 7.5-3.375 7.5-7.5 0-1.968-.844-3.843-2.25-5.25ZM8 14.187c-1.125 0-2.25-.28-3.187-.843l-.188-.094-2.344.656.657-2.25-.188-.281a6.031 6.031 0 0 1-.938-3.281c0-3.375 2.813-6.188 6.188-6.188 1.688 0 3.188.657 4.406 1.782 1.219 1.218 1.781 2.718 1.781 4.406 0 3.281-2.718 6.094-6.187 6.094ZM11.375 9.5c-.188-.094-1.125-.563-1.313-.563-.187-.093-.28-.093-.375.094-.093.188-.468.563-.562.75-.094.094-.188.094-.375.094-.188-.094-.75-.281-1.5-.938-.562-.468-.937-1.124-1.031-1.312-.094-.188 0-.281.094-.375l.28-.281c.095-.094.095-.188.188-.282.094-.093 0-.187 0-.28 0-.095-.375-1.032-.562-1.407-.094-.281-.281-.281-.375-.281h-.375c-.094 0-.282 0-.469.187-.188.188-.656.657-.656 1.594 0 .938.656 1.781.75 1.969.093.094 1.312 2.062 3.187 2.812 1.594.656 1.875.469 2.25.469s1.125-.469 1.219-.844c.188-.469.188-.844.094-.844-.094-.468-.281-.468-.469-.562Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }